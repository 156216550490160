<template>
  <div class="loader-container" v-if="isLoading">
    <ip-loader />
  </div>
  <div class="user-history" v-else>
    <div class="user-history__title">
      История действий пользователя
    </div>
    <div class="user-history__list">
      <div
        v-for="(el, index) in history"
        :key="index"
        class="user-history__elem">
        <div class="date">
          {{ el.date | moment('DD. MM. YYYY') }}
        </div>
        <div class="text">
          <span>{{ actionType(el.action) }}</span>
          <router-link
            v-if="el.action === 'chat_initialized'"
            :to="{ name: 'user', params: { id: el.data.partner.id} }">
            {{ getUserName(el.data.partner) }}
          </router-link>
        </div>
      </div>
      <div class="no-data" v-if="!history.length">
        История отсутствует
      </div>
    </div>
  </div>
</template>

<script>
import apiRoutes from "../../consts/apiRoutes";
import IpLoader from "../../components/IpLoader";
import CommonMixin from "../../mixins/Common.mixin";

const actionType = {
  registration: 'Клиент зарегистрирован',
  documents_submitted: 'Документы отправлены',
  documents_accepted: 'Документы подтверждены',
  documents_rejected: 'Документы отклонены',
  object_created: 'Создан обьект',
  object_documents_submitted: 'Отправлены документы на недвижимость',
  object_documents_accepted: 'Документы на недвижимость подтверждены',
  object_documents_rejected: 'Документы на недвижимость отклонены',
  payment: 'Оплата произведена',
  review_sent: 'Создан отзыв',
  chat_initialized: 'Инициирован чат с',
  review_received: 'Получен отзыв',
  contract_termination_submitted: 'Инициирован разрыв договора',
  contract_termination_acctepted: 'Разрыв договора подтвержден без претензий',
  contract_termination_rejected: 'Инициирован арбитраж разрыва договора',
  violation_reported: 'Сообщение о нарушении',
  violation_reviewed: 'Решение о нарушении'
};

export default {
  mixins: [CommonMixin],

  components: {
    IpLoader
  },

  data() {
    return {
      history: [],
      isLoading: true
    }
  },

  async mounted() {
    try {
      const result = await this.axios.get(apiRoutes.clientHistory.replace('{id}', this.$route.params.id));
      this.history = result.data;
      this.isLoading = false;
    } catch(error) {
      console.warn(error);
      this.isLoading = false;
    }
  },

  methods: {
    actionType(action) {
      return actionType[action];
    }
  }

}
</script>

<style scoped lang="scss">
.user-history{
  background: $white;
  padding: 24px;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__title{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $black;
    flex: none;
    margin-bottom: 16px;
  }
  &__list{
    overflow: auto;
    flex: 1 1 auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $blue;
      outline: 1px solid $gray;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba($black, 0.3);
    }
  }
  &__elem{
    display: flex;
    align-items: flex-start;
    position: relative;
    .date{
      position: relative;
      padding-right: 40px;
      padding-bottom: 12px;
      width: 130px;
      &:after{
        background: $scienceBlue;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        content: '';
        position: absolute;
        right: 15px;
        top: 4px;
      }
      &:before{
        background: $scienceBlue;
        width: 1px;
        top: 4px;
        bottom: -4px;
        content: '';
        position: absolute;
        right: 19px;
      }
    }
    .text{
      position: relative;
    }
    &:last-child{
      .date{
        &:before{
          display: none;
        }
      }
    }
  }
}
</style>